import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { home as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import InfoBox from '../components/InfoBox';
import PageWrapper from '../components/DefaultPageWrapper';

import {
    Expander,
    ExpanderItem,
    Hero,
    Link,
    ScrollMarker,
    SEO,
    TrustIcons,
    Waistband
} from '@brandedholdings/react-components';


class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {variant: 'default'};
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    setupApplicationFocus(e) {
        if (this._personalLoanForm !== null) {
            e.preventDefault();
            ScrollMarker.scrollToMarker('personalLoanFormStart');
            this._personalLoanForm.getWrappedInstance().focusApplication();
        }
    }

    componentDidMount() {
        const match = document.location.href.toLowerCase().match(/(([?&#])startnow=true|([?&#])promoid|([?&#])promo_id|([?&#])utm_source=revpie)/i);

        if (match instanceof Array) {
            this.setState({variant: 'x'});
        }
    }


    render() {
        const page = this.props;

        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        return (
            <PageWrapper>
                <SEO post={ post } />
                <Hero
                    heading="Quickly choose from a variety of finance options - customized just for you!"
                    subheading={ null }
                    actionText="See My Options"
                    actionLink="/v2/get-started/"
                    heroModifiers="hero--home"
                    actionModifiers="button--success button--full"
                >
                    <TrustIcons wrapper={({ children }) => <div>{children}</div>} icons={['secured', 'mcafee-secure']} cssModifiers={['alt']} siteMeta={siteMeta} height={48} />
                </Hero>
                <div className="hero__accent icon icon--arrow-dotted"></div>

                <div className="layout">
                    <div className="layout-content text-align--center">
                        <h3 className="layout-content__heading">What is Finance Matrix?</h3>
                        <div className="layout">
                            <div className="layout-content text-align--center box-shadow">
                                <div className="waistband waistband--default">
                                    <div className="waistband--content">
                                        <p><small>Finance Matrix provides you with a personalized list of trusted lenders and financial products - for all credit types.</small></p>

                                        <p><small>To begin, <a href="/v2/get-started/">click here</a> to view a range of offers, and then choose the financing option that works best for you. It’s that easy.</small></p>

                                    </div>

                                    <ul className="nav-grid">
                                        <li className="nav-grid__item">
                                            <InfoBox headline="Loans"
                                                     icon="loans" />
                                        </li>
                                        <li className="nav-grid__item">
                                            <InfoBox headline="Credit Cards"
                                                     icon="credit-cards" />
                                        </li>
                                        <li className="nav-grid__item">
                                            <InfoBox headline="Debt Relief/Consolidation"
                                                     icon="debt-relief" />
                                        </li>
                                        <li className="nav-grid__item">
                                            <InfoBox headline="Credit Repair and Monitoring"
                                                     icon="credit-repair" />
                                        </li>
                                    </ul>
                                    <div className="waistband--content">
                                        <br />
                                        <p>
                                            <strong>
                                                <Link to="/v2/get-started/"
                                                      className="link__purple cursor__text">
                                                    And More&hellip;
                                                </Link>
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Waistband color="blue">
                    <div className="layout layout">
                        <div className="layout--2-column">
                            <div className="layout-content">
                                <h2 className="layout-content__heading">Find the products & services you&nbsp;need!</h2>
                                <p>Are you looking to consolidate debt? Want to monitor your credit score? How about credit cards or loans? We can&nbsp;help!</p>
                            </div>
                            <div className="sidebar">
                                <br />
                                <br />
                                <Link
                                    to="/v2/get-started/"
                                    className="button button--big button--success">
                                    See My Options
                                </Link>
                            </div>
                        </div>
                    </div>
                </Waistband>

                <div className="layout">
                    <div className="layout-content">
                        <br />
                        <h3 className="layout-content__heading layout-content__heading--centered">How it Works</h3>
                        <ul className="feature-grid">
                            <li className="feature-grid__item">
                                <img src="/assets/images/icons/icon-how-it-works-step-1.svg" className="feature-grid__image" alt="Honest Insights" />
                                <p className="text__purple"><strong>Who do we help</strong></p>
                                <p><small>Good, bad, limited – no matter what your credit is like our goal is to help you quickly find the right offer, customized to your specific needs!</small></p>
                            </li>
                            <li className="feature-grid__item">
                                <img src="/assets/images/icons/icon-how-it-works-step-2.svg" className="feature-grid__image" alt="Huge Partner Network" />
                                <p className="text__purple"><strong>How do we help</strong></p>
                                <p><small>You’ll be shown a range of offers that may include personal loans, small dollar loans, credit cards, and a variety of other options. You can also personalize your results to better suit your needs.</small></p>
                            </li>
                            <li className="feature-grid__item">
                                <img src="/assets/images/icons/icon-how-it-works-step-3.svg" className="feature-grid__image" alt="100% No Obligation Offers" />
                                <p className="text__purple"><strong>Compare and learn more</strong></p>
                                <p><small>Compare the offers and find the one that works best for your needs. Then, you can learn more and get started directly on the advertiser's website. It really is that&nbsp;simple.</small></p>
                            </li>
                        </ul>
                        <br />
                    </div>
                </div>

                <div className="layout">
                    <div className="layout">
                        <div className="layout-content text-align--center box-shadow">
                            <div className="waistband waistband--default">
                                <div className="waistband--content">
                                    <h2 className="layout-content__heading layout-content__heading--centered">You've Got Questions? We've Got Answers.</h2>
                                    <Expander altStyle={ false }>
                                        <ExpanderItem title={ siteMeta.content.faqs.item1.question }>
                                            <p>{ siteMeta.content.faqs.item1.answer }</p>
                                        </ExpanderItem>
                                        <ExpanderItem title={ siteMeta.content.faqs.item2.question }>
                                            <p>{ siteMeta.content.faqs.item2.answer }</p>
                                        </ExpanderItem>
                                        <ExpanderItem title={ siteMeta.content.faqs.item4.question }>
                                            <p>{ siteMeta.content.faqs.item4.answer }</p>
                                        </ExpanderItem>
                                        <ExpanderItem title={ siteMeta.content.faqs.item5.question }>
                                            <p>{ siteMeta.content.faqs.item5.answer }</p>
                                        </ExpanderItem>
                                    </Expander>
                                    <p>
                                        <button onClick={ PageWrapper.openFaqModal } className="button-text-link">
                                            <strong>
                                                See All FAQs
                                            </strong>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Waistband color="blue">
                    <div className="layout layout">
                        <div className="layout--2-column">
                            <div className="layout-content">
                                <h2 className="layout-content__heading">Ready to get started?</h2>
                                <p>Every situation is unique, so we make it easy to get offers that suit your exact financial needs!</p>
                            </div>
                            <div className="sidebar">
                                <br />
                                <br />
                                <Link
                                    to="/v2/get-started/"
                                    className="button button--big button--success">
                                    Get Started
                                </Link>
                            </div>
                        </div>
                    </div>
                </Waistband>
            </PageWrapper>

        );
    }
};

Home.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default Home;
