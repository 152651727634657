import PropTypes from 'prop-types';
import React from 'react';
import { Link } from '@brandedholdings/react-components';
import './default.scss';

function InfoBox(props) {
    const { headline, icon } = props;

    return (
        <span className="info-box">
            <Link to="/v2/get-started/" className="cursor__default">
                <span className={`info-box--icon ${icon ? `icon icon--${icon}` : ''}`} />
            </Link>
            <br />
            <Link to="/v2/get-started/" className="cursor__text">
                <span className="info-box--headline">{ headline }</span>
            </Link>
        </span>
    );
}

InfoBox.propTypes = {
    headline: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
};

export default InfoBox;
